:root {
	--base-12: 0, 0, 0;
	--base-11: 25, 27, 38;
	--base-10: 57, 59, 72;
	--base-09: 88, 90, 103;
	--base-08: 108, 109, 123;
	--base-07: 148, 150, 164;
	--base-06: 180, 181, 196;
	--base-05: 215, 217, 233;
	--base-04: 231, 233, 248;
	--base-03: 240, 241, 255;
	--base-02: 246, 248, 255;
	--base-01: 255, 255, 255;
	--primary-accent-purple: 118, 45, 181;
	--primary-accent-purple-light: 183, 108, 255;
	--primary-accent-purple-dark: 85, 24, 159;
	--primary-accent-blue: 86, 178, 186;
	--primary-accent-blue-light: 138, 228, 236;
	--primary-accent-blue-dark: 79, 156, 161;
	--primary-accent-grey: 25, 27, 38;
	--primary-accent-grey-light: 35, 37, 53;
	--primary-accent-grey-dark: 14, 15, 26;
	--primary: var(--primary-accent-purple);
	--primary-light: var(--primary-accent-purple-light);
	--primary-dark: var(--primary-accent-purple-dark);
	--secondary: var(--primary-accent-blue);
	--secondary-light: var(--primary-accent-blue-light);
	--secondary-dark: var(--primary-accent-blue-dark);
	--tertiary: var(--primary-accent-grey);
	--tertiary-light: var(--primary-accent-grey-light);
	--tertiary-dark: var(--primary-accent-grey-dark);

	// Font Colors
	--font-brand-01: var(--primary-accent-purple-light);
	--font-brand-02: var(--primary-accent-blue);
	--font-dark: var(--base-12);
	--font-light: var(--base-01);
	--font-supportive: var(--base-05);
	--font-disabled: var(--base-12);

	// System Colors
	--error: 233, 38, 54;
	--success: 0, 205, 100;
	--info: 0, 124, 240;
	--pending: 255, 175, 45;
	--purple: 168, 100, 255;

	// Live Casino
	--active-players-color: 244, 244, 244;
	--full-room-color: 153, 153, 153;

	// --notification-text-color: defaults to rgba(var(--base-02), 1);

	// Progress Meter
	--risk-level-base: var(--primary-accent-grey-light);
	--risk-level-0: var(--primary-accent-grey-light);
	--risk-level-1: var(--success);
	--risk-level-2: var(--success);
	--risk-level-3: var(--pending);
	--risk-level-4: var(--error);

	// Type Vars
	--display: normal normal 700 7rem heebo, sans-serif;
	--display-2: normal normal 800 5rem/1.2 heebo, sans-serif;
	--display-3: normal normal 800 3.6rem/1.222 heebo, sans-serif;
	--h1: normal normal 800 2.8rem/1.285 heebo, sans-serif;
	--h2: normal normal 700 2.2rem/1.272 heebo, sans-serif;
	--h3: normal normal 700 1.9rem/1.265 heebo, sans-serif;
	--h4-bold: normal normal 700 1.7rem/1.412 heebo, sans-serif;
	--h4-regular: normal normal 400 1.7rem/1.412 heebo, sans-serif;
	--heading-5: normal normal 400 1.6rem/1.4375 heebo, sans-serif;
	--label-regular: normal normal 400 1.5rem/1.335 heebo, sans-serif;
	--label-bold: normal normal 700 1.5rem/1.335 heebo, sans-serif;
	--caption: normal normal 400 1.2rem/1.335 heebo, sans-serif;
	--caption-bold: normal normal 700 1.2rem/1.335 heebo, sans-serif;
	--small: normal normal 400 1.1rem/1.365 heebo, sans-serif;
	--small-bold: normal normal 700 1.1rem/1.365 heebo, sans-serif;

	// Font Changes 4
	--top-right-corner-link: var(--label-bold);
	--product-icon-text: var(--label-bold);
	--footer-links: var(--label-bold);
	--footer-flag-language-text: var(--label-regular);
	--footer-link-continue-icon-color: rgba(var(--primary-accent-purple-light), 1);

	// Notification Inbox
	// Notification counter
	--unread-message-counter-bg-color: var(--primary-accent-blue-light);
	--unread-message-counter-text-color: var(--base-11);

	// Notification Header
	--my-notifications-header-bg-color: var(--primary-accent-grey-dark);
	--my-notifications-back-button-filter: var(--filter-primary);
	--my-notifications-heading-color: var(--base-01);
	--my-notifications-mark-all-read-color: var(--primary-accent-purple-light);
	--my-notifications-back-button-color: rgba(var(--primary-accent-purple-light), 1);

	// My Activities
	--btn-dots: rgba(var(--primary-accent-purple-light), 1);

	// Notification: Unread message
	--unread-message-notifier-color: var(--primary-accent-blue);
	--unread-message-title-color: var(--base-01);
	--unread-message-subtitle-color: var(--base-01);
	--unread-message-date-color: var(--font-supportive);

	// Notification: Read message
	--read-message-color: var(--font-supportive);
	--read-message-title-color: var(--font-supportive);

	// Notification: No Data
	--no-notifications-icon-color: var(--primary-accent-purple-light);
	--no-notifications-heading-color: var(--base-01);
	--no-notifications-subheading-color: var(--font-supportive);

	// Notification: Full screen popup
	--notification-popup-bg: var(--primary-accent-grey-light);
	--notification-popup-title: var(--base-01);
	--notification-popup-subtitle: var(--base-01);
	--notification-popup-close-icon: var(--primary-accent-purple-light);
	--notification-popup-button-bg: var(--primary-accent-purple-light);
	--notification-popup-button-text: var(--base-01);
	--notification-popup-blur-bg: var(--base-11);
	--notification-popup-dot-active: var(--primary-accent-purple);
	--notification-popup-dot: var(--primary-accent-purple-light);

	// Web-push
	--web-push-popup-bg: var(--primary-accent-grey-light);
	--web-push-popup-title: var(--font-light);
	--web-push-popup-subtitle: var(--font-light);
	--web-push-popup-button-bg: var(--primary-accent-purple-light);
	--web-push-popup-button-text: var(--primary-accent-purple-light);

	// Source Of Wealth
	--source-of-wealth-help-text: var(--base-01);
	--source-of-wealth-form-bg: var(--base-12);
	--source-of-wealth-form-text: var(--base-01);
	--source-of-wealth-dropdown-bg: var(--primary-accent-grey-dark);
	--source-of-wealth-dropdown-border: var(--base-11);

	//embed link
	--embed-link-color: var(--primary-accent-purple);

	// Landing Page 6
	--landing-default-bg-color: rgba(var(--base-11), 1);
	--login-btn-bg: rgba(var(--primary-accent-grey-light), 1);
	--login-btn-text: rgba(var(--base-01), 1);
	--landing-page-logo: rgba(var(--base-01), 1);
	--landing-page-title: rgba(var(--base-01), 1);
	--product-icon-bg: rgba(var(--primary-accent-grey-light), 1);
	--product-icon: rgba(var(--base-01), 1);
	// --product-icon-label: defaults to --base-02
	--terms-and-condition_bg: rgba(var(--base-11), 1);
	--bonus-terms-text: rgba(var(--base-01), 1);
	--bonus-terms-link: rgba(var(--primary-accent-purple), 1);
	--welcome-popup-bg: rgba(var(--primary-accent-purple), 1);
	--welcome-popup-text-color: rgba(var(--primary-accent-grey-light), 1);

	// Head 12
	--head-bg: rgba(var(--base-11), 1);
	--page-head-logo: rgba(var(--base-01), 1);
	--page-head-title: rgba(var(--base-01), 1);
	--account-text: rgba(var(--base-01), 1);
	--account-icon: rgba(var(--base-01), 1);
	--balance-circle: rgba(var(--primary-accent-grey-dark), 1);
	--balance-icon-wrapper: rgba(var(--primary-accent-purple), 1);
	--balance-icon-wrapper-basic: rgba(var(--base-11), 1);
	--balance-shop-animate-points-wrapper: rgba(var(--base-10), 1);
	--balance-text: rgba(var(--base-01), 1);
	--balance-icons: rgba(var(--base-01), 1);
	--page-head-link: rgba(var(--primary-accent-purple-light), 1);
	--page-head-icon: rgba(var(--primary-accent-purple-light), 1);
	--hamburger-menu: rgba(var(--primary-accent-purple-light), 1);
	--balance-header-icon: rgba(var(--primary-accent-grey-light), 1);
	--quick-deposit-icon-color: rgba(var(--base-01), 1);
	--balance-fill-icon-color: rgba(var(--base-01), 1);

	// Chip
	--balance-chip-circle: rgba(var(--primary-accent-grey-dark), 1);
	--balance-chip-icon: var(--font-ligth);
	--balance-chip-text: var(--font-light);
	--balance-icon-chip-wrapper: rgba(var(--primary-accent-grey-light), 1);

	// Buttons 9
	--button-primary-bg: rgba(var(--primary-accent-purple), 1);
	--button-primary-bg-hover: rgba(var(--primary-accent-purple-light), 1);
	--button-primary-text: rgba(var(--base-01), 1);
	--button-secondary-bg: rgba(var(--base-11), 1);
	// --button-secondary-text: defaults to --base-02
	--button-wrapper-bg: var(--sidebar-bg);
	--button-wrapper-bg-light: rgba(var(--primary-accent-blue), 1);
	// --button-register: defaults to --primary
	--button-register-text: rgba(var(--font-light), 1);
	// --button-pag: defaults to --base-11
	--support-button: rgba(var(--primary-accent-grey-light), 1);
	--support-button-content: rgba(var(--base-01), 1);
	// --button-border-radius: defaults to 3px
	--button-disabled-bg: rgba(var(--base-10), 1);
	--button-disabled-text: rgba(var(--font-disabled), 1);
	--button-primary-slider-bg-exeption: rgba(var(--blue-grey-900), 1);

	// Badges
	--badge-primary-bg: rgba(var(--primary-accent-purple), 1);
	--badge-primary-text: rgba(var(--base-01), 1);
	--badge-new-bg: rgba(var(--primary-accent-blue));
	--badge-new-text: rgba(var(--base-02), 1);
	--badge-dark-bg: rgba(var(--primary-accent-blue), 1);
	--badge-dark-text: rgba(var(--base-01), 1);
	--badge-secondary-bg: rgba(var(--primary-accent-blue), 1);
	--badge-secondary-text: rgba(var(--font-dark), 1);

	// Select
	--select-border-bottom-color: rgba(var(--blue-grey-900), 1);
	--select-selected-option-color: rgba(var(--base-01), 1);
	--select-option-color: rgba(var(--base-11), 1);
	--select-option-bg-color: rgba(var(--base-01), 1);
	--select-legend-color: rgba(var(--base-05), 1);
	--select-arrow-down-color: rgba(var(--primary-accent-purple), 1);

	// Link List
	--link-list: rgba(var(--primary-accent-grey), 1);

	// Borders
	--list-border-bottom: rgba(var(--primary-accent-grey-dark), 1);

	// Filters 12
	--filter-bg: rgba(var(--base-11), 1);
	--filter-border-primary: 1px solid rgba(var(--primary-accent-grey-light), 1);
	--filter-bg-secondary: rgba(var(--primary-accent-grey-light), 1);
	--filter-border-secondary: 1px solid rgba(var(--primary-accent-grey-light), 1);
	--filter-idle: rgba(var(--base-01), 1);
	// --filter-idle-secondary: nothing
	--filter-active: rgba(var(--primary-accent-purple-light), 1);
	--filter-active-secondary: rgba(var(--primary-accent-purple-light), 1);
	--filter-bar-active: rgba(var(--primary-accent-purple-light), 1);
	--filter-bar-active-secondary: rgba(var(--primary-accent-purple-light), 1);
	--filter-bar-inactive: rgba(var(--primary-accent-grey-light), 1);
	--filter-hover: rgba(var(--base-01), 1);
	--filter-hover-secondary: rgba(var(--base-01), 1);
	--filter-disabled: rgba(var(--base-11), 1);
	--filter-support-links: rgba(var(--font-light), 1);

	//Mobile search input field 69
	--search-bg: rgba(var(--primary-accent-grey-light), 1);
	--search-text: rgba(var(--font-supportive), 1);
	--search-icon: rgba(var(--primary-accent-purple-light), 1);
	--search-icon-bg-color: var(--submenu-item-bg);
	--search-cancel-font: var(--label-regular);

	//Mobile search results 69
	--search-results-bg: rgba(var(--base-11), 1);
	--search-result-item-bg: rgba(var(--base-11), 1);
	--search-results-caption: rgba(var(--base-01), 1);
	--search-results-caption-font: var(--label-regular);
	--search-results-more: rgba(var(--primary-accent-purple-light), 1);
	--search-no-results-text-font: var(--caption);
	--search-no-results-text-color: rgba(var(--base-01), 1);
	--search-ph-gradient-bottom: rgba(var(--base-09), 0.3);
	--search-ph-gradient-top: rgba(var(--base-09), 1);
	--search-ph-mask: rgba(var(--base-11), 1);

	//Desktop search input field 69
	--search-bg-large: rgba(var(--base-11), 1);
	--search-text-large: rgba(var(--base-05), 1);
	--search-text-desktop-placeholder: rgba(var(--font-supportive), 1);
	--search-icon-large: rgba(var(--primary-accent-purple-light), 1);

	//Desktop search results 69
	--search-no-results-desktop-text-font: normal normal 700 1.9rem/1.412 heebo, sans-serif;
	--search-no-results-desktop-text-color: rgba(var(--base-01), 1);

	// Custom checkboxes
	--custom-checkbox-border-color: var(--base-05);
	--custom-checked-checkbox-bg: var(--primary-accent-purple-light);
	--custom-checked-checkbox-border-color: var(--primary-accent-purple-light);
	--custom-checked-checkmark-color: var(--base-12);
	--custom-checked-disabled-checkbox-bg: var(--base-10);
	--custom-checked-disabled-checkbox-border-color: var(--base-10);
	--custom-checked-disabled-checkbox-checkmark: var(--base-12);

	// Custom radio buttons
	--custom-radio-border: var(--base-05);
	--custom-checked-radio-bg: var(--primary-accent-purple-light);
	--custom-checked-radio-border-color: var(--primary-accent-purple-light);
	--custom-disabled-radio-border-color: var(--base-10);
	--custom-checked-disabled-radio-bg: var(--base-10);
	--custom-checked-disabled-radio-border-color: var(--base-10);

	// Section Head 6
	--main-icon: brightness(0) saturate(100%) invert(49%) sepia(20%) saturate(2502%) hue-rotate(228deg) brightness(102%)
		contrast(101%);
	--main-title: rgba(var(--base-01), 1);
	--main-subtitle: rgba(var(--font-supportive), 1);
	--inverted-main-icon: rgba(var(--primary-accent-purple), 1);
	--inverted-main-title: rgba(var(--base-01), 1);
	--inverted-main-subtitle: rgba(var(--base-01), 1);
	--section-head-main-icon: rgba(var(--base-01), 1);
	--deposit-confirmation-icon-color: rgba(var(--primary-accent-purple), 1);

	// Game Tags 3
	--hot-bg: rgba(var(--primary), 1);
	--new-bg: rgba(var(--secondary), 1);
	--hot-text: rgba(var(--base-01), 1);
	--new-text: rgba(var(--base-01), 1);
	--game-tag-background-color--EXCLUSIVE: rgba(var(--secondary), 1);
	--game-tag-text-color--EXCLUSIVE: rgba(var(--base-01), 1);
	--provider-name-line-height: 16px;
	--game-tag-line-height: 18px;
	--active-players-line-height: 15px;
	--stakes-pill-line-height: 17px;

	// Sign In 2
	--sign-in-box: rgba(var(--base-11), 1);
	--sign-in-box-border: rgba(var(--base-09), 1);

	// Tabs 3
	--product-tab: rgba(var(--base-11), 1);
	--product-tab-content: rgba(var(--base-01), 1);
	--product-tab-border-top: rgba(var(--base-08), 1);

	// Sidebar 3
	--sidebar-bg: rgba(var(--primary-accent-grey-dark), 1);
	// --sidebar-box: defaults to --base-11
	--sidebar-shadow: rgba(var(--base-12), 0.2);
	// --sidebar-border-left: defaults to the same color as sidebar-bg

	// Payments
	--spinner-loading-animation: rgba(var(--base-01), 1);

	// Footer 12
	--footer-icon: rgba(var(--base-02), 1);
	--footer-bg: rgba(var(--primary-accent-grey-dark), 1);
	--footer-box: rgba(var(--primary-accent-grey-light), 1);
	--footer-text: var(--font-supportive);
	--language-text: rgba(var(--base-01), 1);
	--footer-payment-logo-bg: rgba(var(--base-01), 1);
	--footer-payment-icon: rgba(var(--base-07), 1);
	// --footer-social-icon: defaults to --base-02
	--footer-18-icon: rgba(var(--error), 1);
	--footer-18-icon-text: rgba(var(--font-light), 1);
	--footer-logo-18-height: 45px;
	--footer-logo-18-width: 45px;
	--flag-eu: rgba(var(--base-01), 1);
	--flag-eu-dots: rgba(var(--base-11), 1);
	--footer-link-color: rgba(var(--font-light), 1);

	// Hero 6
	--hero-bg: rgba(var(--primary-accent-grey-light), 1);
	--hero-img: var(--brand-pattern);
	--hero-font-color: rgba(var(--base-01), 1);
	--hero-pattern-size: 444px 484px;
	--hero-bg-position: left top, right top;
	--hero-bg-repeat: no-repeat;

	// SEO-content 3
	--seo-read-more-btn: var(--primary-accent-purple);
	--seo-content-border-bottom: rgba(var(--base-09), 1);
	--seo-content-bg: rgba(var(--base-11), 1);

	// Card
	--box-bg: rgba(var(--base-11), 1);
	--browse-circle-bg: rgba(var(--base-11), 1);
	--browse-circle-icon: rgba(var(--primary-accent-purple-light), 1);
	--open-in-new-window-icon: var(--font-supportive);

	// Loader
	--loading-animation-dots-color: rgba(var(--primary-accent-purple), 1);

	// Virtuals
	// --virtuals-root-bg-color: var(--base-09);

	// Highlight-games
	// --highlight-games-bg-primary: defaults to rgba(var(--base-10), 1);
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1);
	// --highlight-games-bg-tertiary: defaults to rgba(var(--base-11), 1);
	// --highlight-games-text-primary: defaults to rgba(var(--base-02), 1);
	// --highlight-games-text-secondary: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-disabled: defaults to rgba(var(--base-06), 1);
	// --highlight-games-text-secondary-disabled: defaults to rgba(var(--base-07), 1);
	// --highlight-games-betslip-balance-wrapper: defaults to rgba(var(--base-11), 1);
	// --highlight-games-betslip-balance-icon-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-betslip-border-color: defaults to rgba(var(--base-09), 1);
	// --highlight-games-betslip-header-text-color: defaults to rgba(var(--highlight-games-text-primary), 1);
	// --highlight-games-video-player-background: defaults to rgba(var(--base-11));
	// --highlight-games-mobile-betslip-bg: defaults to var(--highlight-games-bg-tertiary);
	// --highlight-games-mute-icon-color: defaults to rgba(var(--base-01));
	// --highlight-games-bet-tab-label-text-color: defaults to rgba(var(--base-02), 1);
	// --highlight-games-pending-bet-color: defaults to rgba(var(--pending), 1);
	// --highlight-games-bet-body-bg-color: defaults to rgba(var(--base-11);
	// --highlight-games-bet-betline-bg-color: defaults to rgba(var(--base-10);
	// --highlight-games-void-bet-color:defaults to rgba(var(--base-02);
	//--highlight-games-clear-bets-btn-color: defaults to rgba(var(--base-10), 1);
	// --highlight-games-market-count-text-color: defaults to rgba(var(--base-04), 1);
	// --highlight-games-bet-tab-text-color: rgba(var(--base-06), 1);
	// --highlight-games-bet-header-bg: defaults to --highlight-games-bg-tertiary
	// --highlight-games-bet-tab-border-color: defaults to rgba(var(--primary-accent-purple), 1);
	// --highlight-games-week-event-border: defaults to 0;
	// --highlight-games-week-event-hover-border: defaults to 0;

	// Onboarding
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1)
	// --onboarding-backdrop-bg: defaults to rgba(var(--base-11), 0.85);
	--onboarding-item-bg: rgba(var(--primary-accent-grey-light), 1);
	--highlight-games-carousel-dices: rgba(var(--base-01), 1);

	//Slider input
	--slider-empty-track: var(--base-11);

	// Bonus slider modal
	--bonus-slider-modal-bg: rgba(var(--base-10), 1);

	// propose-locale
	--propose-locale-text: rgba(var(--base-01), 1);

	// New Game Card
	--gameCardTitleDetailColor: var(--base-01);
	--gameCardTitleDetailFont: var(--label-bold);
	--gameCardJackpotBg: var(--button-primary-bg);
	--gameCardJackpotText: rgba(var(--font-light), 1);
	--gameCardJackpotFont: var(--label-bold);
	--gameCardJackpotFontSmall: var(--small-bold);
	--gameCardDetailBg: var(--primary-accent-grey-dark);
	--gameCardTitle: var(--base-01);
	--gameCardTitleSmall: var(--font-supportive);
	--gameCardWinnerBg: var(--primary-accent-grey-dark);
	--gameCardDotBg: var(--primary-accent-purple-light);

	// Game Info
	--gameInfoBg: var(--primary-accent-grey-dark);
	--gameInfoTitleName: var(--base-01);
	--gameInfoTitleProvider: var(--font-supportive);
	--gameInfoLoveButton: var(--primary-accent-grey-light);
	--gameInfoLoveIcon: var(--primary-accent-purple-light);
	--gameInfoDescText: var(--base-01);
	--gameInfoButtonBorder: var(--primary-accent-purple);
	--gameInfoButtonFont: var(--h4-bold);
	--gameInfoButtonText: var(--primary-accent-purple-light);
	--gameInfoDetailBg: var(--base-11);
	--gameInfoDetailTitle: var(--font-supportive);
	--gameInfoDetailAmount: var(--base-01);
	--gameInfoPlayRealButtonBg: var(--primary-accent-purple);
	--gameInfoPlayRealButtonText: var(--base-01);
	--game-info-exit-icon-color: rgba(var(--base-01), 1);

	// Explore Category Carousel
	--category-arrow-icon-color: rgba(var(--font-brand-01), 1);
	--category-arrow-bg: var(--primary-accent-grey-dark);
	--category-title-color: var(--base-01);
	--category-link-color: var(--font-brand-01);

	// Explore Category Page
	--category-page-bg: var(--base-11);
	--category-page-title-color: var(--base-01);
	--category-exit-icon-color: rgba(var(--primary-accent-purple-light), 1);

	// Filter pills
	--filter-pills-bg: rgba(var(--primary-accent-grey-dark), 1);
	--filter-pills-bg-active: rgba(var(--primary-accent-purple-light), 1);
	--filter-pills-text-color-active: rgba(var(--base-01), 1);
	--filter-pills-text-color: rgba(var(--base-01), 1);

	// Submenu items
	--submenu-bg--desktop: var(--filter-bg-secondary);
	--submenu-item-bg--desktop: rgba(var(--primary-accent-grey-dark), 1);
	--submenu-item-bg-active--desktop: rgba(var(--primary-accent-purple), 1);
	--submenu-item-text-color--desktop: rgba(255, 255, 255, 100%);
	--submenu-item-text-color-active--desktop: rgba(var(--base-05), 1);
	--submenu-item-border-color--desktop: transparent;
	--submenu-item-border-color-active--desktop: transparent;
	--submenu-item-bg: rgba(var(--primary-accent-grey-light), 1);
	--submenu-item-bg-active: rgba(var(--primary-accent-purple), 1);
	--submenu-item-text-color: rgba(255, 255, 255, 100%);
	--submenu-item-text-color-active: rgba(var(--base-05), 1);
	--submenu-item-border-color: transparent;
	--submenu-item-border-color-active: transparent;

	// Skeleton placeholders
	--skeleton-placeholder-bg: rgba(var(--base-10), 1);
	--skeleton-placeholder-bg-gradient: linear-gradient(
		-90deg,
		var(--skeleton-placeholder-bg) 0%,
		rgba(var(--base-11), 1) 50%,
		var(--skeleton-placeholder-bg) 100%
	);

	// Shop / Promotions
	--shop-points-balance-bg: rgba(var(--base-11), 1);
	--shop-points-balance-button-bg: rgba(var(--primary-accent-purple), 1);
	--shop-points-faq-button: rgba(var(--primary-accent-purple), 1);
	--bonus-item-detail-bg: rgba(var(--primary-accent-grey-dark), 1);
	--bonus-item-foreground-text: rgba(var(--font-light), 1);
	--bonus-detail-pill-bg: rgba(var(--primary-accent-purple), 1);
	--bonus-welcome-package-item-read-more-button-box: rgba(var(--base-11), 1);
	--bonus-welcome-package-item-bg: rgba(var(--primary-accent-grey-light), 1);

	// Active bonus
	--active-bonus-progress-lane: rgba(var(--primary-accent-grey-dark), 1);
	--active-bonus-progress-bar: rgba(var(--primary-accent-purple-light), 1);

	// Free to play
	--free-to-play-banner-color: rgba(var(--font-light), 1);
	--free-top-play-card-overlay: rgba(var(--opacity-overlay), 0.5);
	--free-to-play-carousel-arrow-color: rgba(var(--font-light), 1);

	// Stepper
	--stepper-active: rgba(var(--primary-accent-purple), 1);
	--stepper-active-text: rgba(var(--base-10), 1);
	--stepper-inactive: rgba(var(--base-07), 1);
	--stepper-inside-text: var(--font-light);

	// Cookie Banner
	--cookie-policy-link: rgba(var(--primary-accent-purple-light), 1);
	//--policy-version-text: defaults to --language-text
	--cookie-toggle-bg-on: rgba(var(--primary-accent-purple-light), 1);
	--cookie-toggle-bg-off: rgba(var(--base-10), 1);
	--cookie-toggle-knob-on: rgba(var(--base-01), 1);
	--cookie-toggle-knob-off: rgba(var(--base-01), 1);
	--cookiebanner-wrapper-bg: rgba(var(--base-11), 1);
	--cookie-policy-footer-bg: rgba(var(--base-11), 1);
	--cookie-policy-footer-wrap-divider: var(--base-09);
	//--cookie-footer-policy-text: defaults to --base-02
	--cookie-footer-wrap-big-bg: rgba(var(--base-11), 1);
	--footer-wrapper-big-divider: var(--base-09);
	--cookie-footer-wrap-small-bg: rgba(var(--base-11), 1);
	--footer-wrapper-small-divider: var(--base-09);
	//--cookie-accept-all-text-big: rgba(var(--base-11), 1);
	//--cookie-accept-all-text-small: rgba(var(--base-11), 1);
	--cookie-save-settings-btn-text: rgba(var(--base-01), 1);
	--cookie-banner-text: rgba(var(--font-supportive), 1);
	--cookie-accept-settings-text: rgba(var(--base-01), 1);
	--cookie-accept-settings-small-text: rgba(var(--base-01), 1);
	--cookie-save-settings-btn-bg: rgba(var(--primary-accent-purple), 1);
	--cookie-small-save-settings-btn-bg: rgba(var(--primary-accent-purple), 1);
	--cookie-small-save-settings-btn-text: rgba(var(--base-01), 1);
	--cookie-link-supportive: rgba(var(--font-supportive), 1);
	--cookie-banner-logo: var(--page-head-logo);

	// Sportsbook
	// StakeInput
	--stake-input-button-bg-color: rgba(var(--primary-accent-grey-dark), 1);
	--stake-input-button-disabled-color: var(--button-disabled-bg);
	--stake-input-bg-color: rgba(var(--primary-accent-grey-light), 1);
	--stake-input-text-color: rgba(var(--font-light), 1);
	--stake-input-icon-color: rgba(var(--primary-accent-purple-light), 1);
	--stake-input-icon-disabled-color: var(--button-disabled-text);

	// WeSpin
	--basic-horizontal-card: rgba(var(--primary-accent-grey-light), 1);
	--basic-horizontal-card-button-wrapper-bg: rgba(var(--base-11), 1);
	--basic-horizontal-card-button-wrapper-border: rgba(var(--primary-accent-purple), 1);
	--basic-horizontal-card-button-svg-fill: rgba(var(--primary-accent-purple-light), 1);
	--basic-horizontal-card-icon-fill: var(--primary-accent-purple-light);
	// --we-spin-clip-card-bg: rgba(var(--primary-accent-grey-light), 1);
	--we-spin-clip-card-text: rgba(var(--base-06), 1);
	--we-spin-clip-card-title: rgba(var(--base-02), 1);
	--we-spin-dashboard-container-bg: rgba(var(--primary-accent-grey-light), 1);
	--we-spin-dashboard-event-detail-border-color: rgba(var(--base-10), 1);
	--we-spin-dashboard-event-detail-title: rgba(var(--font-light), 1);
	--we-spin-dashboard-event-detail-text: rgba(var(--font-light), 1);
	--we-spin-dashboard-event-detail-view-button: var(--primary-accent-purple-light);
	// --we-spin-event-detail-view-starts-in: defaults to rgba(var(--pending), 1);
	// --we-spin-dashboard-settings-link: defaults to rgba(var(--primary-accent-purple), 1);
	--we-spin-dashboard-switch-description-color: rgba(var(--font-light), 1);
	--we-spin-dashboard-switch-label-disabled: rgba(var(--font-light), 1);
	--we-spin-dashboard-switch-toggle-disabled-bg: rgba(var(--base-10), 1);
	--we-spin-dashboard-switch-toggle-disabled-knob-bg: rgba(var(--base-11), 1);
	--we-spin-dashboard-switch-toggle-off-bg: rgba(var(--base-10), 1);
	--we-spin-dashboard-switch-toggle-off-knob-bg: rgba(var(--primary-accent-purple-light), 1);
	--we-spin-dashboard-switch-toggle-on-bg: rgba(var(--primary-accent-purple), 1);
	--we-spin-dashboard-switch-toggle-on-knob-bg: rgba(var(--primary-accent-purple-light), 1);
	// --we-spin-game-room-buyin-disabled-bg: defaults to rgba(var(--base-12), 1);
	--we-spin-livestream-stats-bg: rgba(var(--base-11), 1);
	--we-spin-next-session-top-text-color: rgba(var(--font-light), 1);
	--we-spin-next-session-middle-text-color: rgba(var(--primary-accent-purple-light), 1);
	--we-spin-next-session-bottom-text-color: rgba(var(--font-supportive), 1);
	--we-spin-preview-stream-bg-color: transparent;
	--we-spin-search-bg: rgba(var(--base-10), 1);
	--we-spin-search-text: rgba(var(--base-02), 1);
	--we-spin-tutorial-card-bg-color: var(--primary-accent-grey-light);
	--we-spin-tutorial-card-bg-gradient: none;
	// --we-spin-tutorial-gradient-start: 60%;

	// UI package variables
	// ====================
	--floating-element-bg-color: rgba(var(--primary-accent-grey-dark), 1);
	--floating-element-text-color: rgba(var(--base-10), 1);

	// Lists
	--list-item-background-color: var(--link-list);

	// Date picker
	--date-picker-background: rgba(var(--primary-accent-grey), 1);
	--date-picker-outline: rgba(var(--primary-accent-purple-light), 1);
	--date-picker-selected: rgba(var(--primary), 1);
	--date-picker-text: rgba(var(--font-light), 1);
	--date-picker-text-supportive: rgba(var(--font-supportive), 1);
	--date-picker-text-on-selected: rgba(var(--font-dark), 1);

	// Odds format toggle
	--odds-format-toggle-bg: rgba(var(--primary-accent-grey), 1);

	// Pill
	--pill-bg: rgba(var(--primary-accent-grey-dark), 1);
	--pill-label-color: rgba(var(--font-light), 1);
	--pill-checked-bg: rgba(var(--primary-accent-purple), 1);
	--pill-checked-label-color: rgba(var(--font-light), 1);

	// Selection Button
	--selection-button-color: rgba(var(--primary-accent-grey), 1);
	--selection-button-color-alternative: rgba(var(--primary-accent-grey), 1);
	--selection-button-odds-text: rgba(var(--font-light), 1);
	--selection-button-selection-text: rgba(var(--font-supportive), 1);
	--selection-button-active-color: rgba(var(--primary), 1);
	--selection-button-active-text-color: var(--button-primary-text);
	--selection-button-active-hover-bg-color: rgba(var(--primary-accent-purple-light), 1);
	--selection-button-hover-bg-color: rgba(var(--primary), 1);
	--selection-button-hover-text-color: rgba(var(--font-light), 1);
	--selection-button-disabled-bg-color: rgba(var(--base-10), 1);
	--selection-button-disabled-text-color: rgba(var(--font-disabled), 1);

	// Cards

	--card-bg: rgba(var(--primary-accent-grey-light), 1); // defaults to var(--box-bg);
	--lc-live-casino-card-bg: rgba(var(--base-12), 0.85);
	--lc-card-font-size: 11px;
	// --card-header-caption-font: defaults to --caption;
	// --card-header-caption-font-color: defaults to rgba(var(--base-06));
	// --card-header-title-font: defaults to --label-bold;
	// --card-header-title-font-color: defaults to nothing;
	// --card-media-pill-bg defaults to card-bg
	// --card-media-pill-font defaults to --caption
	// --card-media-pill-border-radius defautls 12px;
	// --card-media-icon-right-bg defaults to --secondary;
	// --card-media-icon-right-fill defaults to --base-02;
	// --card-media-banner-text-bg defaults to --primary;
	// --card-media-banner-text defaults to --base-02;
	// --card-body-text-font-color defaults to --base-06

	// Game card
	--bowtie-guy: rgba(var(--base-06), 1);

	// UI Button dots

	--button-dot-active-bg: rgba(var(--primary-accent-blue-light), 1);
	--button-dot-bg: rgba(var(--base-01, var(--base-08)), 1);

	// Modal
	--modal-wrapper-bg: rgba(var(--primary-accent-grey), 1);
	--modal-wrapper-dark-bg: var(--base-11);
	--qd-modal-cancel-text: var(var(--font-light));
	--qd-modal-contrast-text: var(--primary-accent-purple);
	--quick-deposit-modal-bg: rgba(var(--primary-accent-grey), 1);
	--quick-deposit-amount-input-bg: rgba(var(--primary-accent-grey-light), 1);
	--quick-deposit-methods-collapse-bg: rgba(var(--primary-accent-grey-light), 1);

	// --action-sheet-button-text-color: defaults to rgba(var(--base-02);
	// --action-sheet-button-bg: defaults to rgba(var(--base-08)

	// Carousel
	--carouselTitleFont: var(--h2);
	--carouselTitleFontLarge: var(--display-3);
	--carouselTitleColor: var(--base-01);
	--carouselDescFont: var(--label-regular);
	--carouselDescFontLarge: var(--h4-bold);
	--carouselDescColor: var(--base-01);
	--carouselDotBg: var(--base-09);
	--carouselDotActiveBg: var(--primary-accent-purple-light);
	--carouselArrow: var(--primary-accent-purple-light);
	--carouselArrowBg: rgba(var(--primary-accent-grey-dark), 1);

	//multiLayer Carousel
	--multiLayerCarouselTitleFont: var(--h3);
	--multiLayerCarouselTitleFontLarge: var(--display-2);
	--multiLayerCarouselDescFont: var(--caption);
	--multiLayerCarouselDescFontLarge: var(--h4-regular);
	--multiLayerCarouselButtonBgColor: rgba(var(--primary-accent-purple), 1);
	--multiLayerCarouselButtonColor: var(--base-01);
	--multiLayerCarouselButtonFont: var(--label-bold);
	--multiLayerCarouselButtonFontLarge: var(--h3);

	// Binary Question
	--binary-question-inner-background: rgba(var(--base-09), 1);
	--binary-question-divider-color: rgba(var(--primary-accent-grey-light), 1);

	// tournaments
	--tournaments-sidebar-background: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-sidebar-surface: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-sidebar-divider-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-sidebar-divider-on-surface: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-text-color-overwrite: unset;
	--tournaments-text-on-tag: #fff;
	--tournaments-button-primary-bg: rgba(var(--font-brand-01), 1);
	--tournaments-rank-highlight: rgba(var(--font-brand-02), 1);
	--tournaments-rank-on-highlight: rgba(var(--font-light), 1);
	--tournaments-rank-avatar-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-rank-avatar-on-surface: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-rank-avatar-on-highlight: rgba(var(--base-01), 1);
	--tournaments-rank-avatar-win: rgba(var(--font-brand-02), 1);
	--tournaments-rank-avatar-on-win: rgba(var(--font-light), 1);
	--tournaments-rank-divider-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-results-title: rgba(var(--font-light), 1);
	--tournaments-results-trophy: rgba(var(--font-light), 1);
	--tournaments-results-pill-icon: rgba(var(--font-light), 1);
	--tournaments-list-header-button: rgba(var(--font-brand-01), 1);
	--tournaments-list-item-details-button-text: rgba(var(--font-brand-01), 1);
	--tournaments-list-item-details-button-icon: rgba(var(--font-brand-01), 1);
	--tournaments-list-item-timer: rgba(var(--font-supportive), 1);
	--tournaments-modal-header-button-background: rgba(var(--font-light), 0.15);
	--tournaments-details-caption-alt: rgba(var(--font-supportive), 1);
	--tournaments-details-tab-border: rgba(183, 184, 188, 100%);
	--tournaments-label-regular: normal normal 400 1.5rem/1.333 heebo, sans-serif;
	--tournaments-label-bold: normal normal 700 1.5rem/1.333 heebo, sans-serif;
	--tournaments-caption: normal normal 400 1.2rem/1.333 heebo, sans-serif;
	--tournaments-caption-bold: normal normal 700 1.2rem/1.333 heebo, sans-serif;
	--tournaments-small: normal normal 400 1.1rem/1.272 heebo, sans-serif;
	--tournaments-small-bold: normal normal 700 1.1rem/1.272 heebo, sans-serif;
	--tournaments-name-transform: none;

	// quick deposit mobile toolbar
	--quick-deposit--toolbar-background: rgba(var(--base-11), 1);
	--quick-deposit--button-wrapper-background: var(--primary-accent-purple);
	--quick-deposit--button-wrapper-border: var(--base-12);
	--quick-deposit--text-color: var(--base-01);
	--quick-deposit--icon-container-color: var(--base-01);

	// new-quick-deposit-modal
	--new-qd--change-method-color: rgba(var(--primary-accent-purple), 1);
	--new-qd--amount-input-bg: rgba(25, 27, 38, 100%);
	--new-qd--amount-input-border-color: var(--primary-accent-grey-light);
	--new-qd--amount-input-focus-border-color: rgba(var(--primary-accent-purple), 1);
	--new-qd--amount-input-focus-text-color: rgba(var(--primary-accent-purple-light), 1);
	--new-qd--amount-input-text-color: rgba(var(--font-light), 1);
	--new-qd--disabled-amount-input-bg: rgba(var(--base-11), 1);
	--new-qd--font-color: var(--font-light);
	--new-qd--list-border-color: rgba(var(--primary-accent-grey-light), 1);
	--new-qd--modal-bg: rgba(14, 15, 26, 100%);
	--new-qd--modal-header-close-icon-bg: rgba(var(--base-01), 0.15);
	--new-qd--modal-header-close-icon-color: rgba(249, 249, 249, 100%);

	// new-quick-deposit-toolbar
	--new-qd--back-button-icon-color: rgba(var(--primary-accent-purple-light), 1);
	--new-qd--text-color: rgba(var(--font-light), 1);
	--new-qd--button-wrapper-background: rgba(var(--primary-accent-purple), 1);
	--new-qd--icon-container-color: rgba(var(--base-01), 1);

	// Credit Card
	--credit-card: rgba(var(--base-11), 1);
	--cc-indicator: rgba(var(--base-08), 1);
	--cc-indicator-active: rgba(var(--primary-accent-purple), 1);
	--cc-card-add-btn: rgba(var(--base-06), 1);
	--cc-card-add-btn-text: rgba(var(--primary-accent-purple), 1);

	// Limits
	--limits-notification-icon: var(--primary-accent-purple);
	--collapse-view-chevron: rgba(var(--primary-accent-purple-light), 1);
	--bonus-claim-success: rgba(var(--base-01), 1);

	//notification-warning-banner
	--notif-banner-bg: rgba(var(--primary-accent-grey-light), 1);
	--notif-banner-icon: rgba(var(--primary-accent-purple-light), 1);

	// Responsible Gaming
	--time-limit-reached-label-color: rgba(var(--font-supportive), 1);
	--time-limit-reached-timer-color: rgba(var(--font-light), 1);

	// New input colors
	--input-background-color: var(--primary-accent-grey);
	--input-disabled-background-color: var(--primary-accent-grey);
	--input-border-color: var(--primary-accent-grey-light);
	--input-border-hover-color: var(--primary-accent-purple-light);
	--input-border-focus-color: var(--primary-accent-purple-light);
	--input-border-disabled-color: var(--primary-accent-grey-light);
	--input-border-error-color: var(--error);
	--input-placeholder-color: var(--font-supportive);
	--input-placeholder-disabled-color: var(--base-09);
	--input-font-color: var(--font-light);
	--input-font-focus-color: var(--font-brand-01);
	--input-font-disabled-color: var(--base-09);
	--input-error-color: var(--error);
	--input-helper-text-color: var(--font-dark);
	--select-arrow-color: var(--primary-accent-purple-light);
	--toggle-group-selected-color: var(--font-brand-01);
	--toggle-group-color: var(--font-supportive);

	// Thunderbite
	--thunderbite-modal-bg: rgba(var(--base-12), 0.8);
	--thunderbite-close: rgba(var(--base-01), 1);

	// Support nav
	--opacity-overlay: var(--base-10);
	--propose-locale-head-bg: var(--base-09);
	--propose-locale-border: var(--opacity-overlay);

	// Marketplace & Shop
	--marketplace-success-text-color: rgba(var(--base-01), 1);
	--marketplace-success-subtext-color: rgba(var(--base-01), 1);
	--marketplace-success-bg-color: rgba(var(--base-10));
	--marketplace-success-balance-color: rgba(var(--base-07), 1);
	--marketplace-success-balance-sum-color: rgba(var(--base-01), 1);
	--marketplace-success-btn-bg: rgba(var(--base-01), 0.25);
	--marketplace-continue-btn-text-color: rgba(var(--primary), 1);
	--marketplace-success-main-icon: var(--section-head-main-icon);
	--marketplace-success-close-icon-color: rgba(var(--primary-accent-purple-light), 1);
	--marketplace-progress-bg: rgba(var(--primary-accent-grey-dark), 1);
	--marketplace-progress-bar-bg: rgba(var(--primary-accent-purple-light), 1);
	--marketplace-points-bg: rgba(var(--primary-accent-purple), 1);
	--marketplace-points-text: rgba(var(--font-light), 1);
	--marketplace-sale-bg: rgba(var(--primary-accent-blue), 1);
	--marketplace-sale-text: rgba(var(--font-dark), 1);
	--marketplace-sale-text-bg: rgba(var(--base-01), 0.1);
	--marketplace-info-text: rgba(var(--font-light), 1);
	--marketplace-wagering-text: rgba(var(--font-light), 1);
	--marketplace-card-bg: rgba(var(--primary-accent-grey-dark, 1));
	--marketplace-specials-bg: rgba(var(--primary-accent-grey-dark, 1));
	--marketplace-specials-card-bg: rgba(var(--primary-accent-grey-light, 1));
	--marketplace-chevron: rgba(var(--primary-accent-purple, 1));
	--marketplace-chevron-bg: rgba(var(--primary-accent-grey-light, 1));

	//Mentor UI Gaming Habits -- Playing Behavior box --
	--button-selection-days-select-color: rgba(var(--primary-accent-purple), 1);
	--button-selection-days-color: rgba(var(--primary-accent-grey), 1);
	--your-behaviour-box-chart: rgba(var(--primary-accent-purple), 1);
	--comparable-players-box-chart: rgba(var(--primary-accent-grey), 1);
	--box-info-data-player: rgba(var(--primary-accent-grey), 1);
	// --playing-behaviour-pills-text-selected: rgba(var(--base-01), 1);
	--playing-behaviour-description-text: rgba(var(--font-supportive), 1);
	--playing-behaviour-readmore-btn-color: rgba(var(--primary-accent-purple-light), 1);
	--game-type-icon-color: var(--primary-accent-purple-light);
	--game-type-label-color: rgba(var(--font-light), 1);
	--vertical-line-others: rgba(var(--primary-accent-blue));

	// Transaction history

	--filter-pills-bg-th: var(--base-09);
	--filtering-pills-bg: var(--primary-accent-purple);
	--filtering-pills-disable: var(--base-09);
	--filtering-pills-hover: var(--primary-accent-purple-light);
	--filtering-pill-font-color: var(--font-light);

	// Transaction history notification
	--transaction-history-notification-background-color: rgba(var(--primary-accent-grey-light), 1);
	--transaction-history-notification-icon-color: var(--font-dark);

	// New change bank bottom popup
	--change-bank-account-popup-close-icon-fill: rgba(var(--base-01), 1);
	--change-bank-account-popup-close-icon-bg: rgba(var(--base-09), 1);
	--change-bank-account-popup-bg: rgba(var(--base-11), 1);

	// inhouse jackpot
	--inhouse-jackpot--btn-bg-color: var(--base-11);
	--inhouse-jackpot--btn-bg-color--opted-in: var(--base-11);
	--inhouse-jackpot--btn-bg-color--desktop: var(--primary-accent-grey-dark);
	--inhouse-jackpot--btn-border-color--opted-in: var(--primary-accent-purple-light);
	--inhouse-jackpot--btn-border-color: var(--primary-accent-grey-dark);
	--inhouse-jackpot--btn-text-color: var(--font-light);
	--inhouse-jackpot--btn-text-color--opted-in: var(--primary-accent-purple-light);
	--inhouse-jackpot--btn-text-color--desktop: var(--font-light);
	--inhouse-jackpot--icon-container-bg-color--desktop: var(--primary-accent-grey-light);
	--inhouse-jackpot--icon-container-bg-color--desktop--opted-in: var(--primary-accent-purple);
	--inhouse-jackpot--icon-container-color: var(--font-light);
	--inhouse-jackpot--icon-container-color--desktop: var(--font-light);
	--inhouse-jackpot--icon-container-color--opted-in: var(--primary-accent-purple-light);
	--inhouse-jackpot--icon-container-color--desktop--opted-in: var(--font-light);
	--inhouse-jackpot--modal-background-color: var(--primary-accent-grey-dark);
	--inhouse-jackpot--modal-header-close-icon-color: var(--font-light);
	--inhouse-jackpot--modal-header-close-icon-color--terms-activated: var(--primary-accent-purple-light);
	--inhouse-jackpot--modal-contribution-info-color: var(--font-light);
	--inhouse-jackpot--modal-content-title-color: var(--font-light);
	--inhouse-jackpot--modal-content-description-color: var(--font-light);
	--inhouse-jackpot--modal-content-show-terms-link-color: var(--primary-accent-purple-light);
	--inhouse-jackpot--modal-content-terms-description-color: var(--font-supportive);
	--inhouse-jackpot--modal-content-terms-close-color: var(--primary-accent-purple-light);
	--inhouse-jackpot--modal-content-terms-title-color: var(--font-light);
	--inhouse-jackpot--modal-optin-button-font: var(--label-bold);
	--inhouse-jackpot--modal-optin-button-color: var(--font-light);
	--inhouse-jackpot--modal-optout-button-color: var(--primary-accent-purple-light);
	--inhouse-jackpot--modal-optout-button-border-color: var(--primary-accent-purple-light);
	--inhouse-jackpot--modal-tiers-text-color: var(--font-light);
	--inhouse-jackpot--win-modal-color: var(--font-light);
	--inhouse-jackpot--win-modal-border-color: var(--primary-accent-purple-light);
	--inhouse-jackpot--win-modal-close-button-font: var(--h3);
	--inhouse-jackpot--win-modal-close-button-color: var(--font-light);

	// Communication popup
	--communication-popup-background-color: rgba(var(--tertiary-dark), 1);
	--communication-popup-title-color: rgba(var(--font-light), 1);

	// Game Info
	--game-info-section-background-color: rgba(var(--tertiary-light), 1);
	--game-info-separator-color: rgba(var(--tertiary-light), 1);
	--game-info-background-color: rgba(var(--tertiary-dark), 1);
	--game-info-button-background-color: rgba(var(--tertiary-dark), 1);
	--game-info-card-color: rgba(var(--tertiary-dark), 1);
	--game-info-menu-background: rgba(var(--tertiary-dark), 1);
	--game-info-menu-icon-color: rgba(var(--primary-light), 1);
	--game-info-volatility-icon-color: rgba(var(--primary-light), 1);
	--game-info-jackpot-amount-text-color: rgba(var(--font-light), 1);
	--game-info-row-value-text-color: rgba(var(--font-light), 1);
	--game-info-name-text-color: rgba(var(--font-light), 1);
	--game-info-provider-text-color: rgba(var(--font-light), 0.6);
	--game-info-collapse-header-text-color: rgba(var(--font-light), 1);
	--game-info-play-button-background-color: rgba(var(--primary), 1);
	--game-info-play-button-color: rgba(var(--font-light), 1);
}
